/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0.2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}
.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}
.Toastify__toast {
  --y: 0;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
  overflow: hidden;
}
.Toastify__toast--stacked {
  position: absolute;
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform 0.3s;
}
.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity 0.1s;
}
.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible;
}
.Toastify__toast--stacked[data-collapsed=true]:not(:last-child) > * {
  opacity: 0;
}
.Toastify__toast--stacked:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--g) * 1px);
  bottom: 100%;
}
.Toastify__toast--stacked[data-pos=top] {
  top: 0;
}
.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0;
}
.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top;
}
.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom;
}
.Toastify__toast--stacked:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: scaleY(3);
  z-index: -1;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  -ms-flex: 1;
      flex: 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
      align-self: flex-start;
  z-index: 1;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0;
}
.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }
  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/* Action */
/* Variables  */
:root {
  --color-feedback-error-l_strong: #9a1f00ff;
  --color-feedback-error-l_base: #de0b0bff;
  --color-feedback-error-l_soft: #fbeae9ff;
  --color-feedback-error-d_strong: #fbeae9ff;
  --color-feedback-error-d_base: #de0b0bff;
  --color-feedback-error-d_soft: #6c1914ff;
}
/* Variables  */
:root {
  --color-neutral-neutral-strong-neutral500: #000000ff;
  --color-neutral-neutral-strong-neutral400: #141416ff;
  --color-neutral-neutral-strong-neutral300: #2c2c30ff;
  --color-neutral-neutral-strong-neutral200: #38383dff;
  --color-neutral-neutral-strong-neutral100: #44444bff;
  --color-neutral-neutral-strong-neutral90: #505058ff;
  --color-neutral-neutral-strong-neutral80: #5c5c66ff;
  --color-neutral-neutral-strong-neutral70: #747481ff;
  --color-neutral-neutral-strong-neutral60: #8e8e9aff;
  --color-neutral-neutral-strong-neutral50: #a9a9b2ff;
  --color-neutral-neutral-strong-neutral40: #c4c4caff;
  --color-neutral-neutral-strong-neutral30: #d1d1d6ff;
  --color-neutral-neutral-strong-neutral20: #dfdfe2ff;
  --color-neutral-neutral-strong-neutral10: #ececeeff;
  --color-neutral-neutral-strong-neutral5: #fafafaff;
  --color-neutral-neutral-strong-neutral0: #ffffffff;
}
/* Aliases */
:root {
  --action-error-default: var(--color-feedback-error-l_base);
  --action-error-hover: var(--color-feedback-error-l_strong);
  --action-error-press: var(--color-feedback-error-l_strong);
  --action-error-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Aliases */
:root {
  --action-ghost-border: var(--color-neutral-neutral-strong-neutral50);
  --action-ghost-hover: var(--color-neutral-neutral-strong-neutral5);
  --action-ghost-disable: var(--color-neutral-neutral-strong-neutral20);
  --action-ghost-text: var(--color-neutral-neutral-strong-neutral80);
  --action-ghost-text-disable: var(--color-neutral-neutral-strong-neutral40);
  --action-ghost-press: var(--color-neutral-neutral-strong-neutral10);
}
/* Variables  */
:root {
  --color-primary-spheron-dark-sd100: #141416ff;
  --color-primary-spheron-dark-sd200: #0c0c0dff;
  --color-primary-spheron-dark-sd300: #070708ff;
  --color-primary-spheron-dark-sd400: #040405ff;
  --color-primary-spheron-dark-sd500: #000000ff;
  --color-primary-spheron-dark-sd80: #2c2c30ff;
  --color-primary-spheron-dark-sd60: #43434aff;
  --color-primary-spheron-dark-sd40: #5a5a63ff;
  --color-primary-spheron-dark-sd20: #72727dff;
  --color-primary-spheron-dark-sd10: #898996ff;
}
/* Aliases */
:root {
  --action-neutral-default: var(--color-primary-spheron-dark-sd100);
  --action-neutral-hover: var(--color-primary-spheron-dark-sd10);
  --action-neutral-press: var(--color-primary-spheron-dark-sd20);
  --action-neutral-disable: var(--color-neutral-neutral-strong-neutral20);
  --action-neutral-text: var(--color-primary-spheron-dark-sd100);
  --action-neutral-text-disable: var(--color-neutral-neutral-strong-neutral40);
}
/* Aliases */
:root {
  --action-primary-default: var(--color-primary-spheron-dark-sd100);
  --action-primary-hover: var(--color-primary-spheron-dark-sd80);
  --action-primary-press: var(--color-primary-spheron-dark-sd60);
  --action-primary-disable: var(--color-neutral-neutral-strong-neutral10);
  --action-primary-text: var(--color-neutral-neutral-strong-neutral0);
  --action-primary-text-disable: var(--color-neutral-neutral-strong-neutral40);
}
/* Variables  */
:root {
  --color-primary-spheron-sky-ss100: #141416ff;
  --color-primary-spheron-sky-ss200: #0c0c0dff;
  --color-primary-spheron-sky-ss300: #070708ff;
  --color-primary-spheron-sky-ss400: #050505ff;
  --color-primary-spheron-sky-ss500: #000000ff;
  --color-primary-spheron-sky-ss80: #2c2c30ff;
  --color-primary-spheron-sky-ss60: #43434aff;
  --color-primary-spheron-sky-ss40: #5a5a63ff;
  --color-primary-spheron-sky-ss20: #72727dff;
  --color-primary-spheron-sky-ss10: #898996ff;
}
/* Aliases */
:root {
  --action-secondary-default: var(--color-primary-spheron-sky-ss100);
  --action-secondary-hover: var(--color-primary-spheron-sky-ss200);
  --action-secondary-press: var(--color-primary-spheron-sky-ss300);
  --action-secondary-disable: var(--color-neutral-neutral-strong-neutral10);
  --action-secondary-text: var(--color-neutral-neutral-strong-neutral0);
  --action-secondary-text-disable: var(
    --color-neutral-neutral-strong-neutral40
  );
}
/* Variables  */
:root {
  --color-feedback-success-l_strong: #1c8056ff;
  --color-feedback-success-l_base: #36b37eff;
  --color-feedback-success-l_soft: #e3fcefff;
  --color-feedback-success-d_strong: #e3fcefff;
  --color-feedback-success-d_base: #36b37eff;
  --color-feedback-success-d_soft: #06371eff;
}
/* Aliases */
:root {
  --action-success-default: var(--color-feedback-success-l_base);
  --action-success-hover: var(--color-feedback-success-l_strong);
  --action-success-press: var(--color-feedback-success-l_strong);
  --action-success-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Variables  */
:root {
  --color-feedback-warning-l_strong: #7f6711ff;
  --color-feedback-warning-l_base: #efbf14ff;
  --color-feedback-warning-l_soft: #fff9e5ff;
  --color-feedback-warning-d_strong: #fff9e5ff;
  --color-feedback-warning-d_base: #efbf14ff;
  --color-feedback-warning-d_soft: #4d3b00ff;
}
/* Aliases */
:root {
  --action-warning-default: var(--color-feedback-warning-l_base);
  --action-warning-hover: var(--color-feedback-warning-l_strong);
  --action-warning-press: var(--color-feedback-warning-l_strong);
  --action-warning-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Feedback */
/* Aliases */
:root {
  --feedback-default-bg: var(--color-neutral-neutral-strong-neutral5);
  --feedback-default-cross: var(--color-neutral-neutral-strong-neutral100);
  --feedback-default-text: var(--color-neutral-neutral-strong-neutral70);
}
/* Aliases */
:root {
  --feedback-error-bg: var(--color-feedback-error-l_soft);
  --feedback-error-cross: var(--color-neutral-neutral-strong-neutral100);
  --feedback-error-text: var(--color-feedback-error-l_base);
}
/* Variables  */
:root {
  --color-feedback-info-l_strong: #141416ff;
  --color-feedback-info-l_strong_text: #ffffffff;
  --color-feedback-info-l_soft: #141416ff;
  --color-feedback-info-d_soft: #141416ff;
  --color-feedback-info-d_strong_text: #141416ff;
  --color-feedback-info-d_strong: #ffffffff;
  --color-feedback-info-l_soft_text: #ffffffff;
  --color-feedback-info-d_soft_text: #ffffffff;
}
/* Aliases */
:root {
  --feedback-info-bg: var(--color-feedback-info-l_strong);
  --feedback-info-text: var(--color-feedback-info-l_strong_text);
  --feedback-info-cross: var(--color-neutral-neutral-strong-neutral100);
}
/* Aliases */
:root {
  --feedback-success-bg: var(--color-feedback-success-l_soft);
  --feedback-success-cross: var(--color-neutral-neutral-strong-neutral100);
  --feedback-success-text: var(--color-feedback-success-l_strong);
}
/* Aliases */
:root {
  --feedback-warning-bg: var(--color-feedback-warning-l_soft);
  --feedback-warning-cross: var(--color-neutral-neutral-strong-neutral100);
  --feedback-warning-text: var(--color-feedback-warning-l_strong);
}
/* Cards, Rows, Misc */
/* Aliases */
:root {
  --cards-rows-etc-bg: var(--color-neutral-neutral-strong-neutral0);
  --cards-rows-etc-hover: var(--color-neutral-neutral-strong-neutral5);
  --cards-rows-etc-text: var(--color-neutral-neutral-strong-neutral100);
  --cards-rows-etc-border: var(--color-neutral-neutral-strong-neutral30);
  --cards-rows-etc-icon: var(--color-neutral-neutral-strong-neutral70);
  --cards-rows-etc-link: var(--action-primary-default);
}
/* Base */
/* Variables  */
:root {
  --color-primary-spheron-light-sl100: #ffffffff;
  --color-primary-spheron-light-sl200: #ffffffff;
  --color-primary-spheron-light-sl300: #ffffffff;
  --color-primary-spheron-light-sl80: #e5e5e5ff;
  --color-primary-spheron-light-sl60: #ccccccff;
  --color-primary-spheron-light-sl40: #b2b2b2ff;
  --color-primary-spheron-light-sl20: #999999ff;
  --color-primary-spheron-light-sl10: #808080ff;
}
/* Aliases */
:root {
  --base-bg-color: var(--color-neutral-neutral-strong-neutral0);
  --base-fg-color: var(--color-neutral-neutral-strong-neutral5);
  --base-border-color: var(--color-neutral-neutral-strong-neutral10);
  --base-para-text-color: var(--color-neutral-neutral-strong-neutral100);
  --base-label-bg: var(--color-primary-spheron-light-sl100);
  --base-icon: var(--color-neutral-neutral-strong-neutral70);
  --base-link: var(--color-primary-spheron-dark-sd100);
  --base-heading-text-color: var(--color-neutral-neutral-strong-neutral300);
  --base-sub-text-color: var(--color-neutral-neutral-strong-neutral70);
  --base-light-text-color: var(--color-neutral-neutral-strong-neutral40);
}
/* Form */
/* Aliases */
:root {
  --form-text: var(--color-neutral-neutral-strong-neutral100);
  --form-placeholder: var(--color-neutral-neutral-strong-neutral50);
  --form-border: var(--color-neutral-neutral-strong-neutral30);
  --form-selected: var(--color-primary-spheron-dark-sd100);
  --form-hover: var(--color-neutral-neutral-strong-neutral60);
  --form-error: var(--color-feedback-error-l_base);
  --form-success: var(--color-feedback-success-l_base);
  --form-error-bg: var(--color-feedback-error-l_soft);
  --form-disable: var(--color-neutral-neutral-strong-neutral40);
  --form-selected-color: var(--color-neutral-neutral-strong-neutral0);
  --form-disable-selected: var(--color-neutral-neutral-strong-neutral5);
  --form-disable-text: var(--color-neutral-neutral-strong-neutral30);
}
/* Logo */
/* Aliases */
:root {
  --logo-primary: var(--color-primary-spheron-dark-sd100);
  --logo-secondry: var(--color-primary-spheron-sky-ss100);
  --logo-text: var(--color-primary-spheron-dark-sd100);
  --logo-reverse: var(--color-primary-spheron-dark-sd100);
}
/* Overlay */
/* Variables  */
:root {
  --color-neutral-neutral-alpha-neutral0: #00000000;
  --color-neutral-neutral-alpha-neutral5: #0000000d;
  --color-neutral-neutral-alpha-neutral10: #0000001a;
  --color-neutral-neutral-alpha-neutral20: #00000033;
  --color-neutral-neutral-alpha-neutral30: #0000004d;
  --color-neutral-neutral-alpha-neutral40: #00000005;
  --color-neutral-neutral-alpha-neutral50: #00000080;
  --color-neutral-neutral-alpha-neutral60: #00000099;
  --color-neutral-neutral-alpha-neutral70: #000000b3;
  --color-neutral-neutral-alpha-neutral80: #000000cc;
  --color-neutral-neutral-alpha-neutral90: #000000e6;
  --color-neutral-neutral-alpha-neutral100: #000000ff;
}
/* Aliases */
:root {
  --overlay-bg: var(--color-neutral-neutral-alpha-neutral5);
}
/* Gradient */
:root {
  --gradient-1: linear-gradient(48deg, #8247ff 24.79%, #0057ff 82.89%);
  --gradient-2: linear-gradient(104deg, #551bf9 8.89%, #0057ff 106.9%);
  --gradient-3: linear-gradient(
    104deg,
    #f398e2 8.89%,
    #ffab76 59.85%,
    #ffcb9f 106.9%
  );
  --gradient-4: linear-gradient(
    104deg,
    #ffab76 39.76%,
    #ffcb9f 39.77%,
    #f398e2 106.9%
  );
}
/* Action */
/* Aliases  */
.dark {
  --action-error-default: var(--color-feedback-error-l_base);
  --action-error-hover: var(--color-feedback-error-l_strong);
  --action-error-press: var(--color-feedback-error-l_strong);
  --action-error-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Aliases  */
.dark {
  --action-ghost-border: var(--color-neutral-neutral-strong-neutral200);
  --action-ghost-hover: var(--color-neutral-neutral-strong-neutral300);
  --action-ghost-disable: var(--color-neutral-neutral-strong-neutral90);
  --action-ghost-text: var(--color-neutral-neutral-strong-neutral20);
  --action-ghost-text-disable: var(--color-neutral-neutral-strong-neutral70);
  --action-ghost-press: var(--color-neutral-neutral-strong-neutral300);
}
/* Aliases  */
.dark {
  --action-neutral-default: var(--color-primary-spheron-dark-sd100);
  --action-neutral-hover: var(--color-neutral-neutral-strong-neutral300);
  --action-neutral-press: var(--color-neutral-neutral-strong-neutral300);
  --action-neutral-disable: var(--color-neutral-neutral-strong-neutral90);
  --action-neutral-text: var(--color-primary-spheron-dark-sd100);
  --action-neutral-text-disable: var(--color-neutral-neutral-strong-neutral70);
}
/* Aliases  */
.dark {
  --action-primary-default: var(--color-neutral-neutral-strong-neutral0);
  --action-primary-hover: var(--color-neutral-neutral-strong-neutral5);
  --action-primary-press: var(--color-neutral-neutral-strong-neutral20);
  --action-primary-disable: var(--color-neutral-neutral-strong-neutral300);
  --action-primary-text: var(--color-neutral-neutral-strong-neutral400);
  --action-primary-text-disable: var(--color-neutral-neutral-strong-neutral40);
}
/* Aliases  */
.dark {
  --action-secondary-default: var(--color-primary-spheron-light-sl100);
  --action-secondary-hover: var(--color-primary-spheron-light-sl80);
  --action-secondary-press: var(--color-primary-spheron-light-sl60);
  --action-secondary-disable: var(--color-neutral-neutral-strong-neutral300);
  --action-secondary-text: var(--color-neutral-neutral-strong-neutral400);
  --action-secondary-text-disable: var(
    --color-neutral-neutral-strong-neutral70
  );
}
/* Aliases  */
.dark {
  --action-success-default: var(--color-feedback-success-l_base);
  --action-success-hover: var(--color-feedback-success-l_strong);
  --action-success-press: var(--color-feedback-success-l_strong);
  --action-success-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Aliases  */
.dark {
  --action-warning-default: var(--color-feedback-warning-l_base);
  --action-warning-hover: var(--color-feedback-warning-l_strong);
  --action-warning-press: var(--color-feedback-warning-l_strong);
  --action-warning-text: var(--color-neutral-neutral-strong-neutral0);
}
/* Feedback */
/* Aliases  */
.dark {
  --feedback-default-bg: var(--color-neutral-neutral-strong-neutral300);
  --feedback-default-cross: var(--color-neutral-neutral-strong-neutral50);
  --feedback-default-text: var(--color-neutral-neutral-strong-neutral60);
}
/* Aliases  */
.dark {
  --feedback-error-bg: var(--color-feedback-error-d_soft);
  --feedback-error-cross: var(--color-neutral-neutral-strong-neutral50);
  --feedback-error-text: var(--color-feedback-error-d_strong);
}
/* Aliases  */
.dark {
  --feedback-info-bg: var(--color-feedback-info-d_strong);
  --feedback-info-text: var(--color-feedback-info-d_strong_text);
  --feedback-info-cross: var(--color-neutral-neutral-strong-neutral50);
}
/* Aliases  */
.dark {
  --feedback-success-bg: var(--color-feedback-success-d_soft);
  --feedback-success-cross: var(--color-neutral-neutral-strong-neutral50);
  --feedback-success-text: var(--color-feedback-success-d_strong);
}
/* Aliases  */
.dark {
  --feedback-warning-bg: var(--color-feedback-warning-d_soft);
  --feedback-warning-cross: var(--color-neutral-neutral-strong-neutral50);
  --feedback-warning-text: var(--color-feedback-warning-d_strong);
}
/* Cards, Rows, Misc */
/* Aliases */
.dark {
  --cards-rows-etc-bg: var(--color-neutral-neutral-strong-neutral400);
  --cards-rows-etc-hover: var(--color-neutral-neutral-strong-neutral300);
  --cards-rows-etc-text: var(--color-neutral-neutral-strong-neutral10);
  --cards-rows-etc-border: var(--color-neutral-neutral-strong-neutral200);
  --cards-rows-etc-icon: var(--color-neutral-neutral-strong-neutral70);
  --cards-rows-etc-link: var(--action-secondary-default);
}
/* Base */
/* Aliases */
.dark {
  --base-bg-color: var(--color-neutral-neutral-strong-neutral400);
  --base-fg-color: var(--color-neutral-neutral-strong-neutral300);
  --base-border-color: var(--color-neutral-neutral-strong-neutral300);
  --base-para-text-color: var(--color-neutral-neutral-strong-neutral20);
  --base-label-bg: var(--color-neutral-neutral-strong-neutral0);
  --base-icon: var(--color-neutral-neutral-strong-neutral40);
  --base-link: var(--color-primary-spheron-light-sl300);
  --base-heading-text-color: var(--color-neutral-neutral-strong-neutral5);
  --base-sub-text-color: var(--color-neutral-neutral-strong-neutral60);
  --base-light-text-color: var(--color-neutral-neutral-strong-neutral100);
}
/* Form */
/* Aliases  */
.dark {
  --form-text: var(--color-neutral-neutral-strong-neutral20);
  --form-placeholder: var(--color-neutral-neutral-strong-neutral90);
  --form-border: var(--color-neutral-neutral-strong-neutral70);
  --form-selected: var(--color-primary-spheron-light-sl100);
  --form-hover: var(--color-neutral-neutral-strong-neutral50);
  --form-error: var(--color-feedback-error-l_strong);
  --form-success: var(--color-feedback-success-l_strong);
  --form-error-bg: var(--color-feedback-error-l_soft);
  --form-disable: var(--color-neutral-neutral-strong-neutral200);
  --form-selected-color: var(--color-neutral-neutral-strong-neutral10);
  --form-disable-selected: var(--color-neutral-neutral-strong-neutral400);
  --form-disable-text: var(--color-neutral-neutral-strong-neutral70);
}
/* Logo */
/* Aliases */
.dark {
  --logo-primary: var(--color-neutral-neutral-strong-neutral0);
  --logo-secondary: var(--color-neutral-neutral-strong-neutral0);
  --logo-text: var(--color-neutral-neutral-strong-neutral0);
  --logo-reverse: var(--color-neutral-neutral-strong-neutral0);
}
/* Overlay */
/* Aliases */
.dark {
  --overlay-bg: var(--color-neutral-neutral-alpha-neutral50);
}
/* Gradient */
.dark {
  --gradient-1: linear-gradient(48deg, #8247ff 24.79%, #0057ff 82.89%);
  --gradient-2: linear-gradient(104deg, #551bf9 8.89%, #0057ff 106.9%);
  --gradient-3: linear-gradient(
    104deg,
    #f398e2 8.89%,
    #ffab76 59.85%,
    #ffcb9f 106.9%
  );
  --gradient-4: linear-gradient(
    104deg,
    #ffab76 39.76%,
    #ffcb9f 39.77%,
    #f398e2 106.9%
  );
}
/* Variables  */
:root {
  --spacing-0: 0px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-14: 64px;
  --spacing-20: 80px;
  --spacing-16: 100px;
  --spacing-0_5: 2px;
  --spacing-px: 1px;
  --spacing-1_5: 6px;
  --spacing-2_5: 10px;
  --spacing-3_5: 14px;
  --spacing-13: 56px;
  --spacing-24: 96px;
  --spacing-28: 112px;
  --spacing-52: 208px;
  --spacing-48: 192px;
  --spacing-44: 176px;
  --spacing-40: 160px;
  --spacing-36: 144px;
  --spacing-32: 128px;
  --spacing-80: 320px;
  --spacing-72: 288px;
  --spacing-64: 256px;
  --spacing-60: 240px;
  --spacing-56: 224px;
  --spacing-96: 384px;
}
/* Variables  */
:root {
  --color-other-clickable: #ffffff00;
}
/* COMPONENT STYLES  */
/* SLIDER COMPONENT */
/* SLIDER COMPONENT STYLES */
input[type='range'].input__slider {
  appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  border-radius: 4px;
  /* transition: background 0.5s; */
}
input[type='range'].input__slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}
input[type='range'].input__slider::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}
input[type='range'].input__slider::-ms-track {
  width: 100%;
  height: 4px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range'].input__slider::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  margin-top: -5px !important;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid;
  background-color: var(--color-neutral-neutral-strong-neutral400);
}
:is(.dark input[type='range'].input__slider)::-webkit-slider-thumb {
  background-color: var(--color-neutral-neutral-strong-neutral0);
}
input[type='range'].input__slider::-webkit-slider-thumb {
  border-color: var(--color-neutral-neutral-strong-neutral10);
}
:is(.dark input[type='range'].input__slider)::-webkit-slider-thumb {
  border-color: var(--color-neutral-neutral-strong-neutral300);
}
input[type='range'].input__slider:disabled,
input[type='range'].input__slider:disabled::-webkit-slider-runnable-track,
input[type='range'].input__slider:disabled::-moz-range-track,
input[type='range'].input__slider:disabled::-ms-track {
  cursor: not-allowed;
  opacity: 0.8;
}
input[type='range'].input__slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  margin-top: -5px !important;
  border-radius: 50%;
  cursor: pointer;
  border: 2px;
  border-width: 2px;
}
input[type='range'].input__slider:disabled::-moz-range-thumb {
  cursor: not-allowed;
  opacity: 0.8;
}
input[type='range'].input__slider:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
  opacity: 0.8;
}

